import React from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { window } from 'browser-monads';
import Layout from '../components/layout';
import Nav from '../components/nav';
import SEO from '../components/seo';
// import '../components/home/home.css';
import './all-dive-sites.css'

import headerImage from '../images/dusk-and-gear.jpg';

const lang = {
  en: {
    allSites: 'All',
    dalaroeDivepark: 'Dalarö dive park',
    nynaeshamn: 'Nynäshamn',
    others: 'Other dive sites',
  },
  sv: {
    allSites: 'Alla',
    dalaroeDivepark: 'Dalarö Dykpark',
    nynaeshamn: 'Nynäshamn',
    others: 'Övriga dykmål',
  }
}

const OtherDiveSites = (props) => {
  const diveSitesContent = props.data.allContentfulDiveSites;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/dive-sites/other-dive-sites' : `/dive-sites/other-dive-sites/${currentPage - 1}`
  const nextPage = `/dive-sites/other-dive-sites/${currentPage + 1}`;

  return (
    <Layout>
      <SEO
        title='Dive sites'
        keywords={['diving', 'dykmål', 'nynäshamn', 'dalarö dykpark', 'scuba', 'ship wreck', 'scuba diving stockholm', 'archipelago']}
      />
      <Nav />
      <header>
        <div className="dive-sites__section">
          <div className="dive-sites__hero" style={{ backgroundImage: `url(${headerImage})` }}></div>
          <div className="dive-sites__nav">
            <Link to='/dive-sites' className={window.location.href.endsWith('/dive-sites') > 0 ? 'dive-sites__nav--link selected' : 'dive-sites__nav--link'}>{lang.en.allSites}</Link>
            <Link to='/dive-sites/dalaroe-dykpark' className={window.location.href.indexOf('dive-sites/dalaroe-dykpark') > 0 ? 'dive-sites__nav--link selected' : 'dive-sites__nav--link'}>{lang.en.dalaroeDivepark}</Link>
            <Link to='/dive-sites/nynaeshamn' className={window.location.href.indexOf('dive-sites/nynaeshamn') > 0 ? 'dive-sites__nav--link selected' : 'dive-sites__nav--link'}>{lang.en.nynaeshamn}</Link>
            <Link to='/dive-sites/other-dive-sites' className={window.location.href.indexOf('dive-sites/other-dive-sites') > 0 ? 'dive-sites__nav--link selected' : 'dive-sites__nav--link'}>{lang.en.others}</Link>
          </div>
        </div>
      </header>

      <div className="feed">
        {diveSitesContent.edges.map(edge => (
          <div
            role="button"
            key={edge.node.id}
            className="card"
            style={{
              backgroundImage: `linear-gradient(
                to bottom,
                rgba(10,10,10,0) 0%,
                rgba(10,10,10,0) 50%,
                rgba(10,10,10,0.7) 100%),
                url(${edge.node.featuredImage.fluid.src})
                `}}
            onKeyUp={() => navigate(`/dive-sites/${edge.node.slug}`)}
            onClick={() => navigate(`/dive-sites/${edge.node.slug}`)}
          >
            {edge.node.category.map(categories => (
              <p key={categories.id} className="card__category">{categories.title}</p>
            ))}
            <p className="card__title">{edge.node.title}</p>
          </div>
        ))}
      </div>
      <div className="pagination">
        <div className="pagination__section">
          {!isFirst && (
            <Link to={prevPage} rel='prev'>
              <div className="arrow__back"></div>
            </Link>
          )}
        </div>
        <div className="pagination__section">
          {!isLast && (
            <Link to={nextPage} rel='next'>
              <div className="arrow__next"></div>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default OtherDiveSites;

export const pageQuery = graphql`
  query OtherDiveSitesQuery ($skip: Int!, $limit: Int!) {
    allContentfulDiveSites(
      sort: { fields: [createdAt], order: ASC}
      filter: {
        node_locale: {eq: "en-US",}
        category: {elemMatch: {title: {eq: "Other dive sites"}}}
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          slug
          title
          createdAt
          category {
            title
            id
          }
          featuredImage {
            fluid(maxWidth: 1200, quality: 85) {
              src
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`